import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useSnackbar} from 'notistack';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import m from '../../messages/de.json';
import UserlistsContent from './UserlistsContent';

const query = gql`
  query getUserlists {
    userlists: userlist(
      order_by: {longname: asc}
      where: {deleted: {_eq: false}}
    ) {
      id
      email
      longname
      userrole
      organisation {
        name
      }
    }
  }
`;

const deleteUserlistMuatation = gql`
  mutation updateUserlist($id: Int!, $deleted: Boolean) {
    update_userlist_by_pk(pk_columns: {id: $id}, _set: {deleted: $deleted}) {
      id
    }
  }
`;

const updatePwdMutation = gql`
  mutation actionAdminPwdSet($userId: Int!, $newPwd: String!) {
    actionAdminPwdSet(userId: $userId, newPwd: $newPwd) {
      code
      message
    }
  }
`;

export default function Userlists() {
  const {
    data: resultUserlists,
    error,
    loading,
    refetch
  } = useQuery(query, {
    pollInterval: 60000
  });

  const [onDeleteUserlistMutation] = useMutation(deleteUserlistMuatation, {
    ignoreResults: true
  });

  const [onUpdatePwdMuation] = useMutation(updatePwdMutation);

  const {enqueueSnackbar} = useSnackbar();

  async function onDeleteUserlist(userlist) {
    const dUserlist = {id: userlist.id, deleted: true};
    onDeleteUserlistMutation({variables: dUserlist})
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingDelete.fulfilled, {
          variant: 'success'
        });
        refetch();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(`${m.dataHandlingDelete.rejected} (Error: ${error})`, {
          variant: 'error'
        });
      });
  }

  async function onPwdSetUserlist(userlist, newPwd) {
    const dUserlist = {userId: userlist.id, newPwd};
    onUpdatePwdMuation({variables: dUserlist})
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingPwdSet.fulfilled, {
          variant: 'success'
        });
        refetch();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(`${m.dataHandlingPwdSet.rejected} (Error: ${error})`, {
          variant: 'error'
        });
      });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <UserlistsContent
        onDeleteUserlist={onDeleteUserlist}
        onPwdSetUserlist={onPwdSetUserlist}
        refetch={refetch}
        resultUserlists={resultUserlists}
      />
    </AsyncContent>
  );
}
