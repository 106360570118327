import gql from 'graphql-tag';

const GET_GAMES = gql`
  query getGames($where: game_bool_exp! = {}) {
    games: game(order_by: {executionfrom: desc}, where: $where) {
      id
      templategameperiodid
      title
      boardtype
      taskstatus
      execution
      executionfrom
      numberofplayers
      organisation {
        name
      }
    }
  }
`;

export {GET_GAMES};
