import {Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import EntitytypeDropDown from 'containers/Entity/EntitytypeDropDown';
import m from '../../messages/de.json';
import cs from '../Entityroles/EntityrolesTopBar.module.scss';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function EntityrolesTopBar({filter, onChangeFilter}) {
  function onChangeEntityType(e) {
    let entitytype = e.target.value === '' ? undefined : e.target.value;

    if (parseInt(entitytype) === 0) {
      entitytype = undefined;
    }
    onChangeFilter({entitytype});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>
          {m.EntitiesTopBar.entitytype}
        </Typography>
        <EntitytypeDropDown
          onChange={onChangeEntityType}
          value={filter.entitytype}
        />
      </Toolbar>
    </TopBar>
  );
}

EntityrolesTopBar.propTypes = propTypes;
export default EntityrolesTopBar;
