import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {GET_GAMES} from 'containers/Games/GamesSchemas';
import Authentication from 'utils/Authentication';
import EnvUtils from 'utils/EnvUtils';
import GamesContent from './GamesContent';

export default function Games() {
  const $orgPermissionsFilter =
    Authentication.getOrganisationId() > 0
      ? {
          deleted: {_eq: false},
          organisationid: {_eq: Authentication.getOrganisationId()}
        }
      : {deleted: {_eq: false}};

  const {
    data: resultGames,
    error,
    loading,
    refetch
  } = useQuery(GET_GAMES, {
    pollInterval: 60000,
    variables: {
      where: $orgPermissionsFilter
    }
  });

  const UPDATE_GAME = gql`
    mutation updateGame($id: Int!, $deleted: Boolean) {
      update_game_by_pk(pk_columns: {id: $id}, _set: {deleted: $deleted}) {
        id
      }
    }
  `;

  // challenge: during mutation react re-reanders, why?? solution ignore results
  // https://medium.com/@seniv/improve-performance-of-your-react-apollo-application-440692e37026
  const [updateGame, {errorDel, loadingDel}] = useMutation(UPDATE_GAME, {
    ignoreResults: true
  });

  async function onDeleteGame(game) {
    const dGame = {id: game.id, deleted: true};
    updateGame({variables: dGame})
      .then(() => {
        refetch();
      })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
      });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <GamesContent
        onDeleteGame={onDeleteGame}
        refetch={refetch}
        resultGames={resultGames}
      />
    </AsyncContent>
  );
}
