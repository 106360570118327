import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useHistory,
  Prompt
} from 'react-router-dom';
import GameTable from 'components/GameTable/GameTable';
import Popup from 'components/PopUp/Popup';
import cs from 'containers/Entity/Entity.module.scss';
import Game from 'containers/Game/Game';

const propTypes = {
  onDeleteGame: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  resultGames: PropTypes.object
};

export default function GamesContent({onDeleteGame, refetch, resultGames}) {
  const history = useHistory();
  const {path, url} = useRouteMatch();

  function onRequestCloseMainContent() {
    refetch();
    history.push('/games');
  }

  return (
    <div className={cs.root}>
      <Prompt
        message={(location) =>
          !location.pathname.includes('/close/')
            ? true
            : 'Do you like to change the state of the game?'
        }
      />

      <Switch>
        <Route
          path={[`${url}/new`, `${path}/:id`]}
          render={({match}) => (
            <Popup onRequestClose={onRequestCloseMainContent}>
              <Game id={match.params.id} onClose={onRequestCloseMainContent} />
            </Popup>
          )}
        />
        <Route>
          <Typography gutterBottom variant='h5'>
            Spiele
          </Typography>
          <Button
            color='primary'
            component={Link}
            startIcon={<AddIcon />}
            to='/games/new'
            variant='contained'
          >
            Neues Spiel
          </Button>
          <GameTable games={resultGames.games} onDeleteGame={onDeleteGame} />
        </Route>
      </Switch>
    </div>
  );
}

GamesContent.propTypes = propTypes;
